import React from 'react';
import './kontaktmig.css';

const kontaktmig = () => {
  return (
    <div className="kontakt">
        <div className='button'>
        <a href="mailto:jakob.b.k.k@hotmail.com" className="kontakt-mig">
         Kontakt Mig
        </a>

        </div>
            
      <img src="/Assets/profilbillede.png" alt="Logo" />
        </div>

  );
};

export default kontaktmig;