import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Project1Page from './Pages/Project1Page'
import Project2Page from './Pages/Project2Page'
import Project3Page from './Pages/Project3Page'
import Project4Page from './Pages/Project4Page'
import Project5Page from './Pages/Project5Page'





import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "Project1",
    element: <Project1Page/>,
  },
  {
    path: "Project2",
    element: <Project2Page/>,
  },
  {
    path: "Project3",
    element: <Project3Page/>,
  },
  {
    path: "Project4",
    element: <Project4Page/>,
  },
  {
    path: "Project5",
    element: <Project5Page/>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
